<template>
  <b-container class="col">
    <div class="d-flex align-items-center justify-content-between">
      <b-form-group label="Select Date" class="mb-2">
        <flat-pickr
          v-model="date"
          :config="{
            dateFormat: 'Y-m-d',
            defaultDate: 'today',
          }"
          class="form-control font-small-2 w-auto"
          placeholder="Choose Date"
          readonly="readonly"
          @input="getOrders(date)"
        />
      </b-form-group>
      <b-button
        @click="DownloadAllDO()"
        variant="gradient-primary"
        class="box-shadow-1 px-5"
        v-if="this.rows.length"
        >Download Delivery Order</b-button
      >
    </div>
    <b-card>
      <MyTable
        :columns="columns"
        :rows="rows"
        :is-loading="isLoading"
        :searchEnabled="true"
      >
        <template v-slot="{ props }">
          <span v-if="props.column.field == 'id'">
            <b-button variant="gradient-primary" block>{{
              props.row.id
            }}</b-button>
          </span>
          <span v-else-if="props.column.field == 'sender_location.first_name'">
            {{ props.row.sender_location.first_name }}
            {{ props.row.sender_location.last_name }}
          </span>
          <span
            v-else-if="props.column.field == 'sender_location.address_line_1'"
          >
            {{ props.row.sender_location.address_unit }},
            {{ props.row.sender_location.address_line_1 }},
            {{ props.row.sender_location.address_postcode }},
            {{ props.row.sender_location.address_state }},
            {{ props.row.sender_location.address_country }}
          </span>
          <span v-else-if="props.column.field === 'amount'">
            {{ $helpers.formatTotal(props.row.amount) }}
          </span>
          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="handleModal(props.row)">
                <span>View & Track</span>
              </b-dropdown-item>
              <b-dropdown-item @click="DownloadDO(props.row.id)">
                <span>Download DO</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </MyTable>
    </b-card>
    <b-modal
      ref="modal"
      :title="`Order Information`"
      :size="`xl`"
      hide-footer
      no-close-on-backdrop
    >
      <RoutePlanSummary :summary="this.RoutePlanSummary" />
    </b-modal>
  </b-container>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import RoutePlanSummary from "./RoutePlanSummary.vue";

export default {
  components: {
    flatPickr,
    MyTable,
    RoutePlanSummary,
  },

  data() {
    return {
      rows: [],
      RoutePlanSummary: "",
      date: new Date().toISOString(),
      pageLength: 5,
      isLoading: false,
      columns: [
        {
          label: "Order ID",
          field: "id",
        },
        {
          label: "Pickup Date/Time",
          field: "pickup_date",
        },
        {
          label: "Delivery Date/Time",
          field: "delivery_date",
        },
        {
          label: "Sender's Name",
          field: "sender_location.first_name",
        },
        {
          label: "Recipient's Name",
          field: "recipient_location.first_name",
        },
        {
          label: "Origin Address",
          field: "sender_location.address_line_1",
        },
        {
          label: "Destination Address",
          field: "recipient_location.address_line_1",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Quantity",
          field: "quantity",
        },
        {
          label: "Size",
          field: "item_size",
        },
        {
          label: "Weight",
          field: "item_weight",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },

  created() {
    this.getOrders();
  },
  methods: {
    async getOrders(date = new Date().toISOString()) {
      this.isLoading = true;
      const formattedDate = date.split("T")[0];
      const response = await this.$http.get(
        `orderminidc/filterOrders?order_type=Distributor&delivery_date=${formattedDate}`
      );
      this.rows = response.data.data;
      this.isLoading = false;
    },
    handleModal(data) {
      this.RoutePlanSummary = data;
      this.$refs.modal.show();
    },
    DownloadDO(id) {
      window.open(`${this.$baseURL}/generate_golog_label/${id}`, "_blank");
    },
    async DownloadAllDO() {
      const formattedDate = this.date.split("T")[0];
      const response = await this.$http.get(
        `bulkGenerateLabel?order_type=Distributor&date=${formattedDate}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "DeliveryOrders.pdf");
      document.body.appendChild(fileLink);

      fileLink.click();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
