<template>
  <b-overlay>
    <b-badge variant="primary" class="badge-padding my-1">
      Order {{ this.summary.id }}
    </b-badge>
    <b-row>
      <b-col cols="12" lg="6" class="px-4 mb-2">
        <div class="mb-2">
          <h3>
            <FeatherIcon icon="TruckIcon" size="30" />
            <span class="ml-2">Order Tracking</span>
          </h3>
        </div>

        <AppTimeline>
          <AppTimelineItem v-for="(event, eventIndex) in summary.order_logs" :key="eventIndex" :title="event.message"
            :time="$helpers.formatDateTime(event.created_at)" />
        </AppTimeline>
      </b-col>

      <b-col cols="12" lg="6" class="px-4 mb-2">
        <div class="mb-2">
          <h3>
            <FeatherIcon icon="BoxIcon" size="30" />
            <span class="ml-2">Order Summary</span>
          </h3>
        </div>

        <b-row>
          <b-col cols="12" lg="6">
            <AppTimeline>
              <AppTimelineItem title="Order Overview" icon="ArchiveIcon">
                <div class="mb-2">
                  <h4>Order Overview</h4>
                </div>

                <div class="mb-2">
                  <h5>Status</h5>
                  <b-badge variant="primary" class="badge-padding">
                    {{ this.summary.status }}
                  </b-badge>
                </div>

                <div class="mb-2">
                  <h5>Shipment</h5>
                  <b-badge variant="danger" class="badge-padding">
                    {{ this.summary.order_type }}
                  </b-badge>
                  </div>
              </AppTimelineItem>

              <AppTimelineItem title="Product Details" icon="BoxIcon">
                <div class="mb-2">
                  <h4>Product Details</h4>
                </div>

                <div class="mb-2">
                  <h5>Quantity</h5>
                  <span>
                    {{ this.summary.quantity }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Remarks</h5>
                  <span>
                    {{ this.summary.remarks }}
                  </span>
                </div>
              </AppTimelineItem>
            </AppTimeline>
          </b-col>

          <b-col cols="12" lg="6">
            <AppTimeline>
              <AppTimelineItem title="Address Details" icon="MapIcon">
                <div class="mb-2">
                  <h4>Address Details</h4>
                </div>

                <div class="mb-2">
                  <h5>Sender Name</h5>
                  <span>
                    {{
                      `${this.summary.sender_location.first_name} ${this.summary.sender_location.last_name
                        ? this.summary.sender_location.last_name
                        : ""
                      }`
                    }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Sender Address</h5>
                  <span>
                    {{ this.summary.sender_location.address_line_1 }}
                    {{
                      this.summary.sender_location.address_unit
                    }}, {{ this.summary.sender_location.address_line_1 }}, {{
  this.summary.sender_location.address_postcode }}, {{ this.summary.sender_location.address_state }}, {{
    this.summary.sender_location.address_country }}
                  </span>
                </div>

                <hr />

                <div class="mb-2">
                  <h5>Recipient Name</h5>
                  <span>
                    {{
                      `${this.summary.recipient_location.first_name} ${this.summary.recipient_location.last_name
                        ? this.summary.recipient_location.last_name
                        : ""
                      }`
                    }}
                  </span>
                </div>

                <div class="mb-2">
                  <h5>Recipient Address</h5>
                  <span>
                    {{ this.summary.recipient_location.address_line_1 }}
                  </span>
                </div>
              </AppTimelineItem>
            </AppTimeline>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  name: "RoutePlanSummary",
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    routeId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: {
    summary: Object,
  },
  mounted() {
    console.log("test");
    console.log(this.summary);
  },
};
</script>

<style lang="scss" scoped></style>
